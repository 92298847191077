

























import {Prop, Component, Vue} from 'vue-property-decorator'
import {Getter, Mutation} from 'vuex-class'
import {weeklySlotToString, weekNumberString} from '../utils'
import {WeeklySlot, Preference, Bundle} from '../data'
import PreferenceSelect from './PreferenceSelect.vue';

@Component({
  components: {
    PreferenceSelect
  }
})
export default class WeeklySlotPreference extends Vue {
  @Prop() readonly weeklySlot!: WeeklySlot
  @Getter('bundlesPerSlot') readonly bundlesPerSlot!: Map<string,Bundle[]>
  
  get bundles():Bundle[] {
    const key = weeklySlotToString(this.weeklySlot);
    if (this.bundlesPerSlot.has(key)) {
      const result =  this.bundlesPerSlot.get(key);
      if (result) {
        return result;
      }
    }
    return [];
  }
  get weekNumbers():string[] {
    return this.bundles.map(bundle => weekNumberString(bundle.sessions));
  }
  get title():string {
    if (!this.weeklySlot) {
      return '';
    }
    const ws = this.weeklySlot;
    return weeklySlotToString(ws);
  }
  @Mutation('setSlotPref') setSlotPref!: (payload: unknown) => void
  setPref(preference: Preference): void {
    this.setSlotPref({weeklySlot: this.weeklySlot, preference});
  }
}
