










import {Component, Vue} from 'vue-property-decorator'
import {State, Mutation} from 'vuex-class'
import KeyPreferences from './KeyPreferences.vue';
import { SchedulingInstance } from '../data';
@Component({
  components: {
    KeyPreferences
  }
})
export default class SlotPreferences extends Vue {
  @State('instance') instance?: SchedulingInstance = undefined

  transform(grp: string): string {
    return 'Your preference with regarding to teaching groups of type '+grp;
  }
  @Mutation('setGroupTypePref') change!: (payload: unknown) => void
}
