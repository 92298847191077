




















import {Component, Vue, Prop, Emit} from 'vue-property-decorator'
import PreferenceSelect from './PreferenceSelect.vue';
import { Preference } from '../data';

@Component({
  components: {
      PreferenceSelect
  }
})
export default class KeyPreferences extends Vue {
  @Prop() readonly instruction!: string
  @Prop() readonly keys!: string[]|number[]
  @Prop() readonly keyTransform?: (key: string|number) => string
  @Prop() readonly allowUnavailable!: boolean
  @Emit('change') change(key: string|number, preference: Preference): {key: string|number, preference: Preference} {
    return {key, preference};
  }
  get transformed(): string[] {
    const result = [];
    for (const k of this.keys) {
      if (this.keyTransform) {
        result.push(this.keyTransform(k).toString())
      }
      else {
        result.push(k.toString());
      }
    }
    return result;
  }
}
