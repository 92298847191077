


































import {Component, Vue} from 'vue-property-decorator';
import {Mutation, State} from 'vuex-class';
import {SchedulingInstance} from '../data';

@Component
export default class Capabilities extends Vue {
  @State('instance') instance!: SchedulingInstance
  @State('preferences') preferences!: SchedulingInstance
  @Mutation('setCapability') setCapability!: (payload: unknown) => void
  @Mutation('setUserId') setName!: (payload: unknown) => void
  change(capability: string, status: boolean): void {
    this.setCapability({capability, status})
  }
  get selected(): number {
    return Object.values(this.preferences.capabilities).filter(k => k).length;
  }
}
