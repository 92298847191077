


























import {Component, Vue} from 'vue-property-decorator';
import {State} from 'vuex-class';
import {SchedulingInstance} from '../data';

@Component
export default class Capabilities extends Vue {
  @State('instance') instance!: SchedulingInstance
}
