






































































































import Welcome from './components/Welcome.vue'
import Capabilities from './components/Capabilities.vue';
import SlotPreferences from './components/SlotPreferences.vue';
import GroupPreferences from './components/GroupPreferences.vue';
import ConsecutivePreferences from './components/ConsecutivePreferences.vue';
import DifferentDaysPreferences from './components/DifferentDaysPreference.vue';
import WrapUp from './components/WrapUp.vue';

import {SchedulingInstance} from './data'

import {Component, Vue} from 'vue-property-decorator'
import {State} from 'vuex-class'

@Component({
  components: {
    Welcome,
    Capabilities,
    SlotPreferences,
    GroupPreferences,
    ConsecutivePreferences,
    DifferentDaysPreferences,
    WrapUp
  }
})
export default class App extends Vue {
  step = 1;
  @State('instance') instance!: SchedulingInstance;
  isActive(step: number): boolean {
    if (this.instance) {
      if (this.instance.askSlots.length == 0 && step == 3) {
        return false;
      }
      if (!this.instance.askGroupTypes && step == 4) {
        return false;
      }
      if (!this.instance.askConsecutiveSessions && step == 5) {
        return false;
      }
      if (!this.instance.askDifferentDays && step == 6) {
        return false;
      }
    }
    return true;
  }
  next(): void {
    this.step++;
    while (!this.isActive(this.step)) {
      this.step++;
    }
  }
  prev(): void {
    this.step--;
    while (!this.isActive(this.step)) {
      this.step--;
    }
  }
}

