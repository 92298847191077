























import {Component, Vue} from 'vue-property-decorator';
import {State,Mutation} from 'vuex-class';
import {SchedulingInstance,UserPreferences} from '../data';
import {downloadPreferencesJson} from '../utils'

@Component
export default class Capabilities extends Vue {
  @State('instance') instance!: SchedulingInstance
  @State('preferences') preferences!: UserPreferences
  @Mutation('setSchedulingComment') setComment!: (comment: unknown) => void
  download(): void {
    downloadPreferencesJson(this.preferences, this.instance);
  }
}
